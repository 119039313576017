<template>
    <div id="app">
        <router-view></router-view>
        <Footer></Footer>
    </div>
</template>

<script>
import Footer from "@/components/footer.vue";
export default {
    name: "App",
    components: {
        Footer,
    },
};
</script>

<style lang="scss">
@import url("./assets/css/base.css");

@font-face {
    font-family: FrutigerLTStd;
    src: url("./assets/font/FrutigerLTStd-Roman.ttf");
}
.fade-enter-active {
    animation: bounce-in 0.5s;
}
.fade-leave-active {
    animation: bounce-in 0.5s reverse;
}
.el-date-table td.today span {
    background-color: #ACBDD8;
    color: #ffffff !important;
}
.el-date-table td.end-date span,
.el-date-table td.start-date span {
    background-color: #ACBDD8 !important;
    color: #ffffff !important;
}
.el-date-table td:hover span {
    background-color: #ACBDD8;
    color: #ffffff !important;
}
.el-dropdown-menu__item:hover {
    color: #305A9E !important;
    background-color: transparent !important;
}
.el-select-dropdown__item.selected{
    color:#305A9E !important;
}
/deep/.el-input__inner::-webkit-input-placeholder {
    font-size: 12px;
    background: transparent;
}
@keyframes bounce-in {
    0% {
        width: 0;
        opacity: 0;
    }
    100% {
        width: 500px;
        opacity: 1;
    }
}
.forget-enter-active {
    animation: forget 0.5s;
}
.forget-leave-active {
    animation: forget 0.5s reverse;
}
@keyframes forget {
    0% {
        width: 0;
        opacity: 0;
    }
    100% {
        width: 50%;
        opacity: 1;
    }
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #305A9E !important;
    color: #fff !important;
}
/deep/.el-table__body tr.current-row > td {
    background-color: #305A9E;
    color: #fff;
}
</style>
