import Vue from 'vue'
import axios from 'axios'
import router from '@/route'
import { Message } from 'element-ui'
let isShow = true
const instance = axios.create({
    baseURL: '',
    timeout: 99999,
    headers: { 'Content-Type': 'application/json;charset=utf-8' }
})

instance.interceptors.request.use(function (config) {
    const TOKEN = localStorage.getItem('AUTtoken');
    const lang = localStorage.getItem('lang');
    if (TOKEN) {
        config.headers['token'] = TOKEN
    }
    switch (lang) {
        case 'zh':
            config.headers.lang = '0';
            break
        case 'en':
            config.headers.lang = '2';
            break
        case 'ft':
            config.headers.lang = '1';
            break
        default:
            config.headers.lang = '0';
            break
    }
    return config;
}, function (error) {
    return Promise.reject(error);
})

instance.interceptors.response.use(function (response) {
    if (response.data.code == 0) {
        return response.data;
    } else if (response.data.code == 500) {
        Message({
            type: "error",
            message: response.data.msg
        })
        return Promise.reject(response.data.msg)
    } else if (response.data.code == 401) {

        if (isShow) {
            Message({
                message: response.data.msg,
                type: 'error'
            });
            isShow = false;
            setTimeout(function () {
                isShow = true;
            }, 3000)
        }
        localStorage.removeItem('AUTtoken');
        localStorage.removeItem("userName");
        router.replace({ path: "/login" })
        return Promise.reject(response.data.msg)
    }

}, function (error) {
    return Promise.reject(error);
})
export default instance;