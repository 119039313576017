import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)
const Login = (resolve) => {
    import('@/pages/login').then((module) => {
        resolve(module)
    })
}
const Home = (resolve) => {
    import('@/pages/home').then((module) => {
        resolve(module)
    })
}
const News = (resolve) => {
    import('@/pages/news').then((module) => {
        resolve(module)
    })
}
const AboutUs = (resolve) => {
    import('@/pages/aboutUs').then((module) => {
        resolve(module)
    })
}
const ContactUs = (resolve) => {
    import('@/pages/contactUs').then((module) => {
        resolve(module)
    })
}
const NewsDetails = (resolve) => {
    import('@/pages/news/childrenPages/newsDetails').then((module) => {
        resolve(module)
    })
}
// const Statement = (resolve) => {
//     import('@/pages/statement').then((module) => {
//         resolve(module)
//     })
// }



// const Documentd = (resolve) => {
//     import('@/pages/document').then((module) => {
//         resolve(module)
//     })
// }
const Account = (resolve) => {
    import('@/pages/account').then((module) => {
        resolve(module)
    })
}

const BasicInformation = (resolve) => {
    import('@/pages/account/childrenPages/basicInformation').then((module) => {
        resolve(module)
    })
}
// const FinancialStatus = (resolve) => {
//     import('@/pages/account/childrenPages/financialStatus').then((module) => {
//         resolve(module)
//     })
// }
// const EmploymentInformation = (resolve) => {
//     import('@/pages/account/childrenPages/employmentInformation').then((module) => {
//         resolve(module)
//     })
// }
const DailyPosition = (resolve) => {
    import('@/pages/dailyPosition').then((module) => {
        resolve(module)
    })
}
const DPopen = (resolve) => {
    import('@/pages/dailyPosition/childrenPages/open').then((module) => {
        resolve(module)
    })
}
const DPclosed = (resolve) => {
    import('@/pages/dailyPosition/childrenPages/closed').then((module) => {
        resolve(module)
    })
}
const DPsummary = (resolve) => {
    import('@/pages/dailyPosition/childrenPages/summary').then((module) => {
        resolve(module)
    })
}

const router = new Router({
    routes: [{
        path: '/',
        redirect: '/home',
    },
    {
        path: '/login',
        name: "Login",
        component: Login
    },
    {
        path: '/home',
        name: "Home",
        meta: {
            active: 1,
            requireAuth: true,
        },
        component: Home
    },
    {
        path: '/news',
        name: "News",
        meta: {
            active: 2,
            requireAuth: true,
        },
        component: News
    },
    {
        path: '/newsDetails',
        name: "NewsDetails",
        meta: {
            active: 2,
            requireAuth: true,
        },
        component: NewsDetails
    },
    // {
    //     path: '/statement',
    //     name: "Statement",
    //     meta: {
    //         active: 3,
    //         requireAuth: true,
    //     },
    //     component: Statement
    // },

    // {
    //     path: '/document',
    //     name: "Document",
    //     meta: {
    //         active: 4,
    //         requireAuth: true,
    //     },
    //     component: Documentd
    // },
    {
        path: '/account',
        meta: {
            active: 5,
            requireAuth: true,
        },
        component: Account,
        children: [
            {
                path: '/',
                redirect: 'basicInformation',
            },
            {
                path: "basicInformation",
                meta: {
                    active: 5,
                    activeChildren: "5-1"
                },
                component: BasicInformation
            },
            // {
            //     path: "employmentInformation",
            //     meta: {
            //         active: 5,
            //         activeChildren: "5-2"
            //     },
            //     component: EmploymentInformation
            // },
            // {
            //     path: "financialStatus",
            //     meta: {
            //         active: 5,
            //         activeChildren: "5-3"
            //     },
            //     component: FinancialStatus
            // }
        ]
    },
    {
        path: '/dailyPosition',
        name: "DailyPosition",
        meta: {
            active: 6,
            requireAuth: true,
        },
        component: DailyPosition,
        children: [
            {
                path: '/',
                redirect: 'summary',
            },
            {
                path: "summary",
                meta: {
                    active: 6,
                    activeChildren: "6-1"
                },
                component: DPsummary
            },
            {
                path: "closed",
                meta: {
                    active: 6,
                    activeChildren: "6-2"
                },
                component: DPclosed
            },
            {
                path: "open",
                meta: {
                    active: 6,
                    activeChildren: "6-3"
                },
                component: DPopen
            }
        ]
    },
    {
        path:"/aboutUs",
        name:"AboutUs",
        meta: {
            active: 8,
            requireAuth: true,
        },
        component: AboutUs,
    },
    {
        path:"/contactUs",
        name:"contactUs",
        component: ContactUs,
        meta: {
            active: 7,
        },
    }
    ]
})

const originalPush = Router.prototype.push
const originalReplace = Router.prototype.replace
// push
Router.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}
// replace
Router.prototype.replace = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
    return originalReplace.call(this, location).catch(err => err)
}
router.beforeEach((to, from, next) => {
    if (to.matched.length === 0) {  //如果未匹配到路由
        from.name ? next({ name: from.name }) : next('/');   //如果上级也未匹配到路由则跳转登录页面，如果上级能匹配到则转上级路由
    } else {
        next();    //如果匹配到正确跳转
    }
});
export default router;