<template>
    <div class="footerWrap">
        <div class="footerContent">
            <div class="footerLogo">
                <!-- <img src="../assets/images/w-logo.png" alt=""> -->
            </div>
            <div>
                <ul class="footerNav">
                    <li class="frist" v-if="$route.name !='Login'&& $route.name!==null">
                        <router-link tag="span" to="/home">{{$t('oa.Home')}}</router-link>
                    </li>
                    <li v-if="$route.name !='Login'&& $route.name!==null">
                        <router-link tag="span" to="/aboutUs">{{$t('oa.AboutUs')}}</router-link>
                    </li>
                    <li v-if="$route.name !='Login'&& $route.name!==null">
                        <router-link tag="span" to="/news">{{$t('oa.News')}}</router-link>
                    </li>
                    <li v-if="$route.name !='Login'&& $route.name!==null">
                        <router-link tag="span" to="/dailyPosition/summary">{{$t('oa.dailyPosition')}}</router-link>
                    </li>
                    <li v-if="$route.name !='Login'&& $route.name!==null">
                        <router-link tag="span" to="/account/basicInformation">{{$t('oa.account')}}</router-link>
                    </li>
                    <li v-if="$route.name !='Login'&& $route.name!==null">
                        <router-link tag="span" to="/contactUs">{{$t('oa.coutcontactus')}}</router-link>
                    </li>
                    <li @click="centerDialogVisible1 = true">
                        <!-- <li> -->
                        <router-link tag="span" to="/home">{{$t('oa.UserAgreement')}}</router-link>
                    </li>
                    <li @click="centerDialogVisible2 = true">
                        <!-- <li> -->
                        <router-link tag="span" to="/home">{{$t('oa.Privacypolicy')}}</router-link>
                    </li>
                </ul>
                <div class="tips">
                    <p>COPYRIGHT © 2021 YF SECURITIES PTE LTD</p>
                    <!-- <p class="li"> {{$t('oa.footerTips')}}</p> -->
                </div>
            </div>
        </div>
        <el-dialog append-to-body :visible.sync="centerDialogVisible1" width="50%" center title="User Agreement">
            <userAgreement />
        </el-dialog>
        <el-dialog append-to-body :visible.sync="centerDialogVisible2" width="50%" center title="Privacy Policy">
            <privacyP />
        </el-dialog>
    </div>
</template>
<script>
import privacyP from "@/components/privacyPolicy.vue";
import userAgreement from "@/components/userAgreement.vue";
export default {
    components: {
        userAgreement,
        privacyP,
    },
    data() {
        return {
            centerDialogVisible1: false,
            centerDialogVisible2: false,
        };
    },
};
</script>
<style lang="scss" scoped>
.Privacypolicycontent {
    p {
        line-height: 25px;
        margin-top: 20px;
    }
    h3 {
        font-size: 18px;
    }
}
.confirm {
    background-color: #305A9E;
    border-color: #305A9E;
    color: #fff;
}
// .footerWrap::after {
//     content: "";
//     display: block;
//     background: url("../assets/images/pattern.png");
//     opacity: 0.15;
//     top: 0;
//     left: 0;
//     bottom: 0;
//     right: 0;
//     position: absolute;
//     z-index: 1;
// }

.footerWrap {
    // background-color: #305A9E;
    // background:url('../assets/images/footer-bg.png') no-repeat center center /cover;
    background-color: #BFC5D1;
    position: relative;
    display: flex;
    align-items: center;
    height: 120px;
    .footerContent {
        width: 1320px;
        position: relative;
        z-index: 9;
        
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        .footerLogo {
            display: flex;
            align-items: center;
        }
        .footerNav {
            display: flex;
            justify-content: flex-end;
            margin: 20px 0;
            li {
                padding: 0 20px;
                // border-left: 1px solid #a9a9a9;
                color: #fff;
                cursor: pointer;
                font-size: 14px;
                span {
                    font-size: 14px;
                }
            }
            .frist {
                // padding: 0;
                padding-left: 0;
                border-left: none;
            }
        }
        .tips {
            color: #fff;
            p {
                font-size: 14px;
                line-height: 30px;
                padding-right: 20px;
                text-align: right;
            }
            .li {
                color: #fff;
                padding-left: 20px;
            }
        }
    }
}
</style>