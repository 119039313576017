import Vue from 'vue'
import App from './App.vue'
import router from './route'
import Element from 'element-ui'
import axios from './api'
import VueI18n from 'vue-i18n'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import 'element-ui/lib/theme-chalk/index.css'
import locale from '../node_modules/element-ui/lib/locale/lang/en';
Vue.use(Element,{locale})
Vue.use(VueI18n)
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
Vue.prototype.$axios = axios
Vue.config.productionTip = false

const i18n = new VueI18n({
  locale: 'CN',
  messages: {
    'CN': require('./lang/zh.js'),
    'EN': require('./lang/en.js'),
    'TC': require('./lang/ft.js')
  }
});
Vue.prototype.format = function (timestamp = null, fmt = 'yyyy-mm-dd') {
  timestamp = parseInt(timestamp);
  if (!timestamp) timestamp = Number(new Date());
  if (timestamp.toString().length == 10) timestamp *= 1000;
  let date = new Date(timestamp);
  let ret;
  let opt = {
    "y+": date.getFullYear().toString(), // 年
    "m+": (date.getMonth() + 1).toString(), // 月
    "d+": date.getDate().toString(), // 日
    "h+": date.getHours().toString(), // 时
    "M+": date.getMinutes().toString(), // 分
    "s+": date.getSeconds().toString() // 秒
  };
  for (let k in opt) {
    ret = new RegExp("(" + k + ")").exec(fmt);
    if (ret) {
      fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
    };
  };
  return fmt;
}
Vue.prototype.setDate = function(){
  let Dates =  new Date();
  return `${Dates.getFullYear()}-${Dates.getMonth() + 1 <= 9 ? `0` + (Dates.getMonth() + 1) : (Dates.getMonth() + 1) }-${Dates.getDate()}`
}
router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth) { // 判断该路由是否需要登录权限
    if (localStorage.getItem('AUTtoken')) {
      next();
    } else {
      next({
        path: '/login'
      })
    }
  }
  else {
    next();
  }
  /*如果本地 存在 token 则 不允许直接跳转到 登录页面*/
  if (to.fullPath == "/login") {
    if (localStorage.getItem('AUTtoken')) {
      next({
        path: from.fullPath
      });
    } else {
      next();
    }
  }
});
new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
