module.exports = {
    oa: {
        Welcomeh:"Welcome",
        startTime:"Start Time",
        endTime:"End Time",
        emialverification:"Email Verification",
        phoneverification:"Phone Verification",
        yourName:"Your Name",
        phone:"Phone",
        emailCus:"Email",
        subject:"Subject",
        message:"Message",
        enterYourName:"Please enter your name",
        enterPhone:"Please enter your phone",
        eneterMessage:"Please enter your information",
        eneterSubject:"Please enter subject",
        contcontactusTips:"You are welcome to contact us through the following <br /> methods. Our team is happy to assist you.",
        footerTips:"Investment is risky, purchase should be cautious!",
        coutcontactus:"Contact Us",
        aboutTIps:"YHS focus on providing comprehensive financial services to our high-net-worth <br /> and institutional customers around the world to achieve their financial goals",
        UserAgreement:"User Agreement",
        ContactusTips:"You are welcome to contact us through email below. ",
        next:"Next",
        prev:"Prev",
        AccountH:"Account Setting",
        AboutUs:"About Us",
        preview:"Preview",
        aboutUsContent:`YFS focus on providing comprehensive financial services to our high-net-worth and institutional customers around the world to achieve their financial goals.<br />
        We have an experienced and motivated team to provide outstanding services to our accredited and institutional customers. Our brokerage business specializes in securities brokerage and FX trading services covering international markets such as Singapore, Hong Kong and the United States
        `,
        Total:"Total",
        dailyPosition:"Statements",
        dailyPositionTitle1:"You can view and download your account statement here.",
        dailyPositionTitle2:"Statement",
        
        summary:"Summary",
        closed:"Closed",
        open:"Open",
        description:"Description",
        cashActivitySummary:"Cash Activity Summary",
        OpeningBalance:"Opening Balance",
        InstrumentPurchased:"Opening Balance",
        InstrumentSold:"Instrument Sold",
        FundsDeposit:"Funds Deposit",
        FundsDisbursed:"Funds Disbursed",
        Income:"Income",
        Expense:"Expense",
        Others:"Others",
        ClosingBalance:"Closing Balance",
        IncomeExpenseSummary:"Income & Expense Summary",
        Dividends:"Dividends",
        Interest:"Interest",
        Fees:"Fees",
        TotalIncome:"Total Income (Expense)",
        MarginSummary:"Margin Summary",
        EndingNetAsset:"Ending Net Asset Value as",
        MarginRequirement:"Margin Requirement",
        ForeignExchange:"Foreign Exchange",
        MoneyMarkets:"Money Markets",
        Stocks:"Stocks",
        FixedIncome:"Fixed Income",
        MutualFunds:"Mutual Funds",
        PortfolioExposureSummary:"Portfolio Exposure Summary",
        Investment:"Investment",
        ProductType:"Product Type",
        Quantity:"Quantity",
        AvaOpeningPrice:"Ava.Opening Price",
        AvaClosingPrice:"Ava.Closing Price",
        ClosingPrice:"Closing Price",
        PL:"P&L",
        PortfolioExposureSummary:"Portfolio Exposure Summary",
        InitialMarginReq:"Initial Margin Req",
        MaintenanceMarginReq:"Maintenance Margin Req",
        MaintenanceMarginRate:"Maintenance Margin Rate",

        CurrentValue:"Current Value",
        PriorValue:"Prior Value",
        PeriodChange:"Period Change",
        Change:"%Change",
        Idle:"Idle",
        PerformanceSummary:"Performance Summary",
        CostBasis:"Cost Basis as of End of",
        UnrealizedGain:"Unrealized Gain (Loss)",
        RealizedGain:"Realized Gain (Loss)",
        FundsDeposited:"Funds Deposited / (Disbursed)",
        TotalIncome:"Total Income / (Expense)",
        SecuritiesReceived:"Total Income / (Expense)",
        PercentageDailyGains:"Percentage Daily Gains / (Loses)",
        TotalAvailableFunds:"Total Available Funds",
        DocumentDownLoad:"DownLoad",
        Nodatatodownload:"No data to download",

        accountTitleInfor:"Account Setting",
        accountTitle1:" You can click the “change password” button at the top right corner to <br /> change your login password. If you wish to update your personal <br /> particulars, please contact our operations team.",
        accountTitle2:"To change login password,Press the “change password” button at top right corner.",
        nowSetInfor:"Now fill in your information",
        Resetsuccess:"Change password successfully",
        enterPasswordAgain:"Please confirm the new password again",
        passwordsAreInconsistent:"The two passwords are inconsistent",
        homeTitle:`YF Securities`,
        homeText1:`The fund administration portal provides one-stop service <br /> where you can easily access to the latest information.`,
        homeText2:"Must be able run when system is bootup.",
        newsTitle:"You can view our latest news here.<br /> if searching is needed, you can enter the information on the right top field and press search button.",
        forgetPassword:"Forgot password",
        help:"Help",
        reast:"Reset",
        newsTitleInfor:"News",
        statementTitleInfor:"Statement",
        documentTitleInfor:"Document",
        confoimNewPassword:"Confirm new password",
        forgetTips:"Please enter the verification code in the email to reset password",
        submit:"Submit",
        sendVerification:"Send code",
        enterconfoimNewPassword:"Please confirm the new password again",
        retry:"S retry",
        city:"City",
        postCode:"Post Code",
        state:"State/County",
        county:"County",
        enterCity:"Please enter the city",
        enterPostCode:"Please enter the Post code",
        enterState:"Please enter state / Country",
        enterCounty:"Please enter County",
        footerInfor:"By using our website, you agree to the use of cookies as set out in",
        Privacypolicy:"Privacy Policy",
        emailSuccess:"Sent successfully",
        riskScore1:"Particularly significant risk",
        riskScore2:"Major risks",
        riskScore3:"General risk",
        riskScore4:"Slight risk",
        riskScore5:"No risk",
        liberal:"Liberal professions",
        staff:"Company staff",
        retire:"Retire",
        Unemployment:"Unemployment",
        steSuccess:"Modification successful.",
        oldPassword:"Old Password",
        enterOldPassword:"Please enter the old Password",
        newPassword:"New Password",
        enterNewPassword:"Please enter new password",
        cancel:"Cancel",
        confirm:"Confirm",
        reset:"Change Password",
        enterAccount:"Please enter account",
        enterPassword:"Please enter password",
        enterVerification:"Please enter Verification",
        Welcome:"Welcome to login",
        Account:"Account",
        Password:"Password",
        Verification:"Verification",
        Sign:"Sign in",
        Home: "Home",
        News: "Highlights",
        statement: "Statement",
        document: "Document",
        loginOut: "Logout",
        details: "view details",
        searchNews: "Search",
        searchStatement: "Search",
        searchDocument: "Search",
        baseInfor: "Basic Information",
        Employment: "Employment Information",
        Finance: "Financial Status",
        account: "Account Setting",
        enterAccount: "Please enter account",
        userName: "User Name",
        enterUserName: "Please enter user name",
        email: "Email Address",
        enteremail: "Please enter email address",
        mobile: "Telephone",
        enterMobile: "Please enter phone number",
        gender: "Gender",
        choiceGender: "Please select gender",
        idNo: "General Information",
        enteridNo: "Please input your General information",
        birth: "Date of Birth",
        choiceBirth: "Please select date of birth",
        Marital: "Marital Status",
        choiceMarital: "Please choose marriage status",
        Residential: "Residential Address",
        enterResidential: "Please enter the residential address",
        Employment: "Employment Status",
        enterEmployment: "Please enter employment status",
        occupation: "Occupation",
        enterOccupation: "Please enter occupation",
        post: "Position",
        enterPost: "Please enter job",
        UnitName: "Company Name",
        enterUnitName: "Please enter company name",
        Nature: "Business Scope",
        enterNature: "Please enter the business scope",
        contactNo: "Company Contact Information",
        enterContact: "Please enter the Company contact information",
        unitEmail: "Company Email",
        enterunitEmail: "Please enter the company email address",
        unitaddress: "Company Address",
        enterInitAddress: "Please enter the company address",
        income: "Income",
        enterIncome: "Please enter income",
        assets: "Asset",
        enterAssets: "Please input asset",
        flowingWater: "Fund Records",
        enterflowingWater: "Please input pipelining",
        risk: "Risk Assessment",
        enterRisk: "Please enter risk assessment",
        preservation: "Preservation",
        male:"Male",
        female:"Female",
        mail:"Mailing Address",
        entermail:"Please enter the mailing address",
        married:"Married",
        unmarried:"Unmarried",
        noData:"No-Data",
        date:"Date",
        title:"Subject",
        saveSuccess:"Saved successfully",
        LogOut:"Log Out",
        emtAddress:"Room 1701, 17th Floor, Shui On Centre, 6-8 Harbour Road, Wanchai, Hong Kong",
        smsverification:"SMS Verification",
        homeBannertTitle1:"LEVERAGING SINGAPORE <br /> WHILE ENGAGING THE WORLD",
        homeBannertTitle2:"Together We Discover New Opportunities"
    }
}