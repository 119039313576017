module.exports = {
    oa: {
        Welcomeh:"歡迎",
        startTime:"開始時間",
        endTime:"結束時間",
        emialverification:"郵箱驗證",
        phoneverification:"手機驗證",
        yourName:"你的姓名",
        phone:"手機",
        emailCus:"郵箱",
        subject:"主題",
        message:"信息",
        enterYourName:"請輸入你的姓名",
        enterPhone:"請輸入你的手機",
        eneterMessage:"請輸入信息",
        eneterSubject:"請輸入主題",
        contcontactusTips:"如有需要，你可透過以下方式聯繫<br />我們。我們的團隊很樂意為您服務",
        footerTips:"投資有風險，購買要謹慎！",
        ContactusTips:"歡迎你透過以下電郵地址與我們聯絡",
        aboutTIps:"YF Securities致力為我們尊貴的客戶實現理財目標",
        coutcontactus:"聯繫我們",
        next:"下一篇",
        prev:"上一篇",
        AccountH:"帳戶設定",
        UserAgreement:"平台協議",
        AboutUs:"關於我們",
        preview:"預覽",
        aboutUsContent:`YFS focus on providing comprehensive financial services to our high-net-worth and institutional customers around the world to achieve their financial goals.<br />
        We have an experienced and motivated team to provide outstanding services to our accredited and institutional customers. Our brokerage business specializes in securities brokerage and FX trading services covering international markets such as Singapore, Hong Kong and the United States
        `,
        Total:"總",
        dailyPosition:"結單",
        dailyPositionTitle1:"你可在這裡瀏覽及下載你的結單",
        dailyPositionTitle2:"結單",
        summary:"摘要",
        closed:"已平倉",
        open:"開倉",
        description:"描述",
        cashActivitySummary:"現金活動摘要",
        OpeningBalance:"未結餘額",
        InstrumentPurchased:"已購買金融工具",
        InstrumentSold:"已出售金融工具",
        FundsDeposit:"存入資金",
        FundsDisbursed:"資金支出",
        Income:"收入",
        Expense:"支出",
        Others:"其他",
        ClosingBalance:"期末余额",
        IncomeExpenseSummary:"收支總匯",
        Dividends:"股利",
        Interest:"利息",
        Fees:"費用",
        TotalIncome:"總收入（支出）",
        MarginSummary:"保證金摘要",
        EndingNetAsset:"最終資產淨值為",
        MarginRequirement:"保證金要求",
        ForeignExchange:"外匯交易",
        MoneyMarkets:"貨幣市場",
        Stocks:"股票",
        FixedIncome:"固定收益",
        MutualFunds:"共同基金",
        PortfolioExposureSummary:"投資組合風險摘要",
        Investment:"投資",
        ProductType:"產品類型",
        Quantity:"數量",
        AvaOpeningPrice:"平均開市價",
        AvaClosingPrice:"平均收市價",
        ClosingPrice:"收市價",
        PL:"盈利虧損",
        PortfolioExposureSummary:"投資組合風險摘要",
        InitialMarginReq:"初始保證金要求",
        MaintenanceMarginReq:"維持保證金要求",
        MaintenanceMarginRate:"維持保證金利率",

        CurrentValue:"現值",
        PriorValue:"先前值",
        PeriodChange:"期間變化",
        Change:"%變化",
        Idle:"不動",
        PerformanceSummary:"表現摘要",
        CostBasis:"截至期末的成本基礎",
        UnrealizedGain:"未實現的收益（虧損）",
        RealizedGain:"實現的收益（虧損）",
        FundsDeposited:"資金 存入/支出",
        TotalIncome:"總收入/（支出）",
        SecuritiesReceived:"證券 收取 /（已收回）",
        PercentageDailyGains:"每日收益/(虧損) %",
        TotalAvailableFunds:"可用資金總額",
        DocumentDownLoad:"下載",
        Nodatatodownload:"暫無數據無法下載",
        accountTitleInfor:"帳戶設定",
        accountTitle1:" 你可以點擊右上角的「更改密碼」來變更你的登入<br />密碼,如需要更新你的個人資料，請聯繫我們的團隊",
        accountTitle2:"如需更改登录密码，按下右上角的“更改密码”按钮",
        nowSetInfor:"预览",
        Resetsuccess:"更改密碼成功",
        enterPasswordAgain:"請再次確認新密碼",
        passwordsAreInconsistent:"兩次密碼輸入不一致",
        homeTitle: `YF Securities`,
        homeText1:"基金行政管理平臺提供一站式服務，您可以在這平臺上輕鬆的瀏覽最新資訊",
        homeText2:"必須能够在系統啟動時運行",
        newsTitle:"向用戶顯示公司提供的新聞提要或通知<br />已有一個新聞/通知源，可用於向用戶顯示",
        forgetPassword:"忘記密碼",
        help:"幫助",
        reast:"重置",
        newsTitleInfor:"新聞",
        statementTitleInfor:"結單",
        documentTitleInfor:"檔案",
        confoimNewPassword:"確認新密碼",
        forgetTips:"請輸入電郵中的驗證碼來重置密碼",
        submit:"提交",
        sendVerification:"發送驗證碼",
        enterconfoimNewPassword:"請再次確認新密碼",
        retry:"S 後重試",
        city:"都市",
        postCode:"郵遞區號",
        state:"州/國家",
        county:"縣",
        enterCity:"請輸入都市",
        enterPostCode:"請輸入郵遞區號",
        enterState:"請輸入州/國家",
        enterCounty:"請輸入縣",
        footerInfor:"使用我們的網站，即表示您同意使用隱私政策中規定的cookie",
        Privacypolicy:"隱私政策",
        emailSuccess:"發送成功",
        riskScore1:"特別重大風險",
        riskScore2:"重大風險",
        riskScore3:"一般風險",
        riskScore4:"輕微風險",
        riskScore5:"無風險",
        liberal:"自由職業",
        staff:"公司員工",
        retire:"退休",
        Unemployment:"失業",
        steSuccess:"修改成功",
        oldPassword:"舊密碼",
        enterOldPassword:"請輸入舊密碼",
        newPassword:"新密碼",
        enterNewPassword:"請輸入新密碼",
        cancel:"取消",
        confirm:"確認",
        reset:"更改密碼",
        enterAccount:"請輸入帳號",
        enterPassword:"請輸入密碼",
        enterVerification:"請輸入驗證碼",
        Welcome:"歡迎登錄",
        Account:"帳號",
        Password:"密碼",
        Verification:"驗證碼",
        Sign:"登入",
        Home: "首頁",
        News: "資訊",
        statement: "結單",
        document: "檔案",
        loginOut: "登出",
        details: "查看詳情",
        searchNews: "搜索",
        searchStatement: "搜索",
        searchDocument: "搜索",
        baseInfor: "基本資料",
        Employment: "就業資料",
        Finance: "財務狀況",
        account: "帳戶設定",
        enterAccount: "請輸入帳戶",
        userName: "用戶名",
        enterUserName: "請輸入用戶名",
        email: "郵箱",
        enteremail: "請輸入郵箱",
        mobile: "電話",
        enterMobile: "請輸入電話",
        gender: "性別",
        choiceGender: "請選擇性別",
        idNo: "基本信息",
        enteridNo: "請輸入基本信息",
        birth: "出生日期",
        choiceBirth: "請選擇出生日期",
        Marital: "婚姻狀態",
        choiceMarital: "請選擇婚姻狀態",
        Residential: "居住地址",
        enterResidential: "請輸入居住地址",
        Employment: "就業狀態",
        enterEmployment: "請輸入就業狀態",
        occupation: "職業",
        enterOccupation: "請輸入職業",
        post: "職務",
        enterPost: "請輸入職務",
        UnitName: "單位名稱",
        enterUnitName: "請輸入單位名稱",
        Nature: "經營範圍",
        enterNature: "請輸入經營範圍",
        contactNo: "公司聯繫方式",
        enterContact: "請輸入公司聯繫方式",
        unitEmail: "公司郵箱",
        enterunitEmail: "請輸入公司郵箱",
        unitaddress: "公司地址",
        enterInitAddress: "請輸入公司地址",
        income: "收入",
        enterIncome: "請輸入收入",
        assets: "資產",
        enterAssets: "請輸入資產",
        flowingWater: "流水",
        enterflowingWater: "請輸入流水",
        risk: "風險評估",
        enterRisk: "請輸入風險評估",
        preservation: "保存",
        male:"男",
        female:"女",
        enterResidential:"請輸入居住地址",
        mail:"郵寄地址",
        married:"已婚",
        unmarried:"未婚",
        noData:"暫無數據",
        date:"時間",
        title:"標題",
        saveSuccess:"保存成功",
        LogOut:"登出",
        emtAddress:"香港灣仔港灣道6-8號瑞安中心17樓1701室",
        smsverification:"短信驗證",
        homeBannertTitle1:"立足亞洲 服務世界",
        homeBannertTitle2:""
    }
}