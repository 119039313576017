module.exports = {
    oa: {
        Welcomeh:"欢迎",
        startTime:"开始时间",
        endTime:"结束时间",
        emialverification:"邮箱验证",
        phoneverification:"手机验证",
        yourName:"你的姓名",
        phone:"手机",
        emailCus:"邮箱",
        subject:"主题",
        message:"信息",
        enterYourName:"请输入姓名",
        enterPhone:"请输入手机",
        eneterMessage:"请输入信息",
        eneterSubject:"请输入主题",
        contcontactusTips: "如有需要，你可透过以下方式联系<br />我们，我们的团队很乐意为您服务",
        footerTips: "投资有风险，购买需谨慎!",
        ContactusTips: "欢迎你透过以下电邮地址与我们联络。",
        aboutTIps: "YF Securities致力为我们尊贵的客户实现理财目标",
        coutcontactus: "联系我们",
        UserAgreement: "平台协议",
        AccountH: "账户设定",
        next: "下一篇",
        prev: "上一篇",
        AboutUs: "关于我们",
        preview: "預覽",
        aboutUsContent: `YFS focus on providing comprehensive financial services to our high-net-worth and institutional customers around the world to achieve their financial goals.<br />
        We have an experienced and motivated team to provide outstanding services to our accredited and institutional customers. Our brokerage business specializes in securities brokerage and FX trading services covering international markets such as Singapore, Hong Kong and the United States
        `,
        Total: "总",
        dailyPosition: "结单",
        dailyPositionTitle1: "每日账户总结为您提供每<br />个工作日结束时的帐户余额",
        dailyPositionTitle2: "结单",
        summary: "摘要",
        closed: "已平仓",
        open: "开仓",
        description: "描述",
        cashActivitySummary: "现金活动摘要",
        OpeningBalance: "未结余额",
        InstrumentPurchased: "已购买金融工具",
        InstrumentSold: "已出售金融工具",
        FundsDeposit: "存入资金",
        FundsDisbursed: "支出资金",
        Income: "收入",
        Expense: "支出",
        Others: "其他",
        ClosingBalance: "期末余额",
        IncomeExpenseSummary: "收支总汇",
        Dividends: "股利",
        Interest: "利息",
        Fees: "费用",
        TotalIncome: "总收入（支出）",
        MarginSummary: "保证金摘要",
        EndingNetAsset: "最终资产净值为",
        MarginRequirement: "保证金要求",
        ForeignExchange: "外汇交易",
        MoneyMarkets: "货币市场",
        Stocks: "股票",
        FixedIncome: "固定收益",
        MutualFunds: "共同基金",
        Investment: "投资",
        ProductType: "产品类型",
        Quantity: "数量",
        AvaOpeningPrice: "平均开市价",
        AvaClosingPrice: "平均收市价",
        ClosingPrice: "收市价",
        PL: "盈利亏损",
        PortfolioExposureSummary: "投资组合风险摘要",
        InitialMarginReq: "初始保证金要求",
        MaintenanceMarginReq: "维持保证金要求",
        MaintenanceMarginRate: "维持保证金利率",
        CurrentValue: "现值",
        PriorValue: "先前值",
        PeriodChange: "期间变化",
        Change: "%变化",
        Idle: "不动",
        PerformanceSummary: "表现摘要",
        CostBasis: "截至期末的成本基础",
        UnrealizedGain: "未实现的收益（亏损）",
        RealizedGain: "实现的收益（亏损）",
        FundsDeposited: "资金 存入/支出",
        TotalIncome: "总收入/（支出）",
        SecuritiesReceived: "证券 收取 /（已收回）",
        PercentageDailyGains: "每日收益/(亏损) %",
        TotalAvailableFunds: "可用资金总额",
        DocumentDownLoad: "下载",
        Nodatatodownload: "暂无数据无法下载",
        accountTitleInfor: "帐户设定",
        accountTitle1: "你可以点击右上角的「更改密码」来变更你的登入<br />密码，如需要更新你的个人资料，请联系我们的团队",
        accountTitle2: "如需更改登录密码，按下右上角的“更改密码”按钮。",
        Resetsuccess: "更改密码成功",
        enterPasswordAgain: "请再次确认新密码",
        passwordsAreInconsistent: "两次密码输入不一致",
        homeTitle: `YF Securities`,
        homeText1: " 基金行政管理平台提供一站式服务，您可以在这平台上轻松的浏览最新资讯",
        homeText2: "必须能够在系统启动时运行",
        newsTitle: "您可以在這裡查看我們最新的新聞。<br />如需搜索，則可以在右上方字段中輸入信息，然後按搜索按鈕",
        forgetPassword: "忘记密码",
        help: "帮助",
        reast: "重置",
        confoimNewPassword: "确认新密码",
        forgetTips: "请输入电邮中的验证码来重置密码。",
        submit: "提交",
        sendVerification: "发送验证码",
        enterconfoimNewPassword: "请再次确认新密码",
        retry: "S 后重试",
        city: "城市",
        postCode: "邮政编码",
        state: "州/国家",
        county: "县",
        enterCity: "请输入城市",
        enterPostCode: "请输入邮政编码",
        enterState: "请输入州/国家",
        enterCounty: "请输入县",
        footerInfor: "使用我们的网站，即表示您同意使用隐私政策中规定的cookie",
        Privacypolicy: "隐私政策",
        emailSuccess: "发送成功",
        statementTitleInfor: "结单",
        documentTitleInfor: "文档",
        newsTitleInfor: "新闻",
        nowSetInfor: "现在填写你的信息",
        riskScore1: "特别重大风险",
        riskScore2: "重大风险",
        riskScore3: "一般风险",
        riskScore4: "轻微风险",
        riskScore5: "无风险",
        liberal: "自由职业",
        staff: "公司员工",
        retire: "退休",
        Unemployment: "失业",
        steSuccess: "修改成功.",
        cancel: "取消",
        confirm: "确认",
        reset: "更改密码",
        oldPassword: "旧密码",
        enterOldPassword: "请输入旧密码",
        newPassword: "新密码",
        enterNewPassword: "请输入新密码",
        enterAccount: "请输入账号",
        enterPassword: "请输入密码",
        enterVerification: "请输入验证码",
        Welcome: "欢迎登录",
        Account: "账号",
        Password: "密码",
        Verification: "验证码",
        Sign: "登录",
        Home: "首页",
        News: "资讯",
        statement: "结单",
        document: "文档",
        loginOut: "退出登录",
        details: "查看详情",
        searchNews: "搜索",
        searchStatement: "搜索",
        searchDocument: "搜索",
        baseInfor: "基本信息",
        Employment: "就业信息",
        Finance: "财务状况",
        account: "帐户设定",
        enterAccount: "请输入账户",
        userName: "用户名",
        enterUserName: "请输入用户名",
        email: "邮箱",
        enteremail: "请输入邮箱",
        mobile: "电话",
        enterMobile: "请输入电话",
        gender: "性别",
        choiceGender: "请选择性别",
        idNo: "基本信息",
        enteridNo: "请输入基本信息",
        birth: "出生日期",
        choiceBirth: "请选择出生日期",
        Marital: "婚姻状态",
        choiceMarital: "请选择婚姻状态",
        Residential: "居住地址",
        enterResidential: "请输入居住地址",
        mail: "邮寄地址",
        entermail: "请输入邮寄地址",
        Employment: "就业状态",
        enterEmployment: "请输入就业状态",
        occupation: "职业",
        enterOccupation: "请输入职业",
        post: "职务",
        enterPost: "请输入职务",
        UnitName: "单位名称",
        enterUnitName: "请输入单位名称",
        Nature: "经营范围",
        enterNature: "请输入经营范围",
        contactNo: "公司联系方式",
        enterContact: "请输入公司联系方式",
        unitEmail: "公司邮箱",
        enterunitEmail: "请输入公司邮箱",
        unitaddress: "公司地址",
        enterInitAddress: "请输入公司地址",
        income: "收入",
        enterIncome: "请输入收入",
        assets: "资产",
        enterAssets: "请输入资产",
        flowingWater: "流水",
        enterflowingWater: "请输入流水",
        risk: "风险评估",
        enterRisk: "请输入风险评估",
        preservation: "保存",
        male: "男",
        female: "女",
        mail: "邮寄地址",
        entermail: "请输入邮寄地址",
        married: "已婚",
        unmarried: "未婚",
        noData: "暂无数据",
        date: "时间",
        title: "标题",
        saveSuccess: "保存成功",
        LogOut: "退出登录",
        emtAddress:"香港湾仔港湾道6-8号瑞安中心17楼1701室",
        smsverification:"短信验证",
        homeBannertTitle1:"立足亚洲 服务世界",
        homeBannertTitle2:""
    }
}